import { Helper } from "./helper";
import { PermissionCodes } from "./constant";

export class Permission {
    canView: boolean;
    canAdd: boolean;
    canEdit: boolean;
    protected canDelete: boolean;
    
    constructor(
        helper: Helper,
        featureCode: string
    ) { 
        this.canView = helper.canAccess(featureCode, PermissionCodes.view);
        this.canAdd = helper.canAccess(featureCode, PermissionCodes.add);
        this.canEdit = helper.canAccess(featureCode, PermissionCodes.edit);
        this.canDelete = helper.canAccess(featureCode, PermissionCodes.delete);
    }
}