import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/helpers/auth.guard';
import { RoutePaths, RouteLinks } from './core/helpers/constant';
import { LoginComponent } from './modules/system/login/login.component';

const appRoutes: Routes = [
    {
        path: RoutePaths.index,
        loadChildren: './modules/index/index.module#IndexModule',
        canActivate: [AuthGuard]
    },
    {
        path: RoutePaths.login,
        component: LoginComponent
    },
    {
        path: RoutePaths.catalog,
        loadChildren: './modules/catalog/catalog.module#CatalogModule',
        canActivate: [AuthGuard]
    },
    {
        path: RoutePaths.voucher,
        loadChildren: './modules/voucher/voucher.module#VoucherModule',
        canActivate: [AuthGuard]
    },
    {
        path: RoutePaths.warehouse,
        loadChildren: './modules/warehouse/warehouse.module#WarehouseModule',
        canActivate: [AuthGuard]
    },
    {
        path: RoutePaths.report,
        loadChildren: './modules/report/report.module#ReportModule',
        canActivate: [AuthGuard]
    },
    {
        path: RoutePaths.system,
        loadChildren: './modules/system/system.module#SystemModule',
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: RouteLinks.itemlot_searching,
        pathMatch: 'full',
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true })
        // RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
