
export class VoucherItem {
  voucherItemId: number;
  voucherId: number;
  itemCode: string;
  itemName: string;
  qty: number;
  netWeight: number;
  requestQty: number;
  requestWeight: number;

  public constructor(init?: Partial<VoucherItem>) {
    Object.assign(this, init);
  }
}
