import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { IssueInventoryRecordService } from '../../../core/services/issueInventoryRecord.service';

import { Helper } from '../../../core/helpers/helper';
import { DateFormats, FeatureCodes } from '../../../core/helpers/constant';
import { VoucherDetailBase } from '../../../core/helpers/voucherDetailBase';
import { VoucherItem } from '../../../core/models/voucherItem';
import { VoucherDetail } from '../../../core/models/voucherDetail';

@Component({
  templateUrl: 'iss-inv-record-detail.dialog.html',
})
export class IssueInventoryRecordDetailDialog extends VoucherDetailBase implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  constructor(
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<IssueInventoryRecordDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: Helper,
    protected issInvRecordSv: IssueInventoryRecordService) {
    super(dialog, helper, FeatureCodes.issueInventoryRecord, issInvRecordSv, data.warehouseId);
  }

  ngOnInit() {
    this.load();
  }

  private load() {
    if (this.data == null) return;

    this.helper.loading(true);
    this.issInvRecordSv.getVoucherItemDetail(this.data.voucherId).subscribe(resp => {
      this.items = resp.items as VoucherItem[];
      this.sortedItems = this.items.slice();
      this.getItemTotal(this.items);

      this.details = resp.details as VoucherDetail[];
      this.sortedDetails = this.details.slice();
      this.getItemLotTotal(this.detailFooter, this.details);

      if (this.items.length > 0) {
        this.viewLotByItem(this.items[0].voucherItemId, false);
      }

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  //-----------------------------------------------------------------------
  // Item
  //-----------------------------------------------------------------------
  itemFilterCallback(value: VoucherItem[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }
    return value.filter(item => (item.itemCode != null && item.itemCode.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.itemName != null && item.itemName.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.qty != null && item.qty.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.netWeight != null && item.netWeight.toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
  }

  //-----------------------------------------------------------------------
  // ItemLot
  //-----------------------------------------------------------------------
  itemLotFilterCallback(value: VoucherDetail[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }
    return value.filter(item => (item.maLot != null && item.maLot.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.netWeight != null && item.netWeight.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.created != null && moment(item.created).format(DateFormats.ddMMyyyy).indexOf(q) > -1) ||
      (item.createdBy != null && item.createdBy.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.maLot2 != null && item.maLot2.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.created2 != null && moment(item.created2).format(DateFormats.ddMMyyyy).indexOf(q) > -1) ||
      (item.createdBy2 != null && item.createdBy2.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.cellCode2 != null && item.cellCode2.toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
  }

  //-----------------------------------------------------------------------
  // Detail
  //-----------------------------------------------------------------------
  detailFilterCallback(value: VoucherDetail[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }
    return value.filter(item => (item.maLot != null && item.maLot.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.netWeight != null && item.netWeight.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.created != null && moment(item.created).format(DateFormats.ddMMyyyy).indexOf(q) > -1) ||
      (item.createdBy != null && item.createdBy.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.maLot2 != null && item.maLot2.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.created2 != null && moment(item.created2).format(DateFormats.ddMMyyyy).indexOf(q) > -1) ||
      (item.createdBy2 != null && item.createdBy2.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.cellCode2 != null && item.cellCode2.toString().toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.itemCode != null && item.itemCode.toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
  }

  //-----------------------------------------------------------------------
  //download Voucher - all ItemLot
  //-----------------------------------------------------------------------
  exportVoucherItemLot(voucherId: number) {
    if (voucherId == null) return;

    this.helper.loading(true);
    this.issInvRecordSv.exportVoucherItemLot(voucherId).subscribe(resp => {
      this.helper.download(resp);

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);

      let that = this;
      this.helper.getHttpErrorResponse(ex, function (msg: string) {
        that.helper.showWarning(msg);
      });
    });
  }

  //-----------------------------------------------------------------------
  //download Voucher - the Item
  //-----------------------------------------------------------------------
  downloadVoucherItem(voucherId: number, voucherItemId: number) {
    if (voucherId == null || voucherItemId == null) return;

    this.helper.loading(true);
    this.issInvRecordSv.downloadVoucherItem(voucherId, voucherItemId).subscribe(resp => {
      this.helper.download(resp);

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);

      let that = this;
      this.helper.getHttpErrorResponse(ex, function (msg: string) {
        that.helper.showWarning(msg);
      });
    });
  }
}