
export class WarehouseCellItemLot {
  itemLotId: number;
  maLot: string;
  netWeight: number;
  itemCode: string;
  itemName: string;
  cellCode: string;
  ngayNhapKho: Date;
  voucherCodeNhap: string;
  origVoucherNhap: string;
  ngayXuatKho: Date;
  voucherCodeXuat: string;
  origVoucherXuat: string;
  status: string;

  public constructor(init?: Partial<WarehouseCellItemLot>) {
    Object.assign(this, init);
  }
}
