import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { VoucherBaseService } from './voucherBase.service';

import { environment } from '../../../environments/environment';
import { Helper } from '../helpers/helper';

import { Voucher } from '../models/voucher';
import { VoucherItem } from '../models/voucherItem';
import { Item } from '../models/Item';

@Injectable({ providedIn: 'root' })
export class ReceiveInventoryService extends VoucherBaseService {

    constructor(
        protected http: HttpClient,
        protected helper: Helper) {
        super(http, helper);
        this.apiUrl = `${environment.apiUrl}Web/NhapKho/`;
    }

    //search Item
    searchItem(keyword: string, voucherId: number) {
        if (keyword == null || keyword === '') {
            return of([] as Item[]);
        }
        
        keyword = this.helper.encodeURIComponent(keyword);
        let voucherIdStr = '';
        if (voucherId != null) voucherIdStr = voucherId.toString();

        return this.http.get(`${this.apiUrl}SearchItem?keyword=${keyword}&voucherId=${voucherIdStr}`)
            .pipe(
                map((resp: Item[]) => { return resp })
            );
    }

    delete(voucherId: number) {
        if (voucherId == null) return;

        return this.http.delete(`${this.apiUrl}${voucherId}`)
            .pipe(
                map((resp: any) => { return resp })
            );
    }

    setFinish(voucherId: number, isFinish: boolean) {
        return this.http.get(`${this.apiUrl}SetFinish/${voucherId}/${isFinish}`)
            .pipe(
                map((resp: any) => { return { voucher: resp.voucher as Voucher, message: resp.message as string } })
            );
    }

    //Voucher Item
    saveVoucherItem(v: VoucherItem) {
        if (v == null) return;

        return this.http.put(`${this.apiUrl}UpdateVoucherItem`, v)
            .pipe(
                map((resp: Voucher) => { return resp })
            );
    }

    deleteVoucherItem(voucherId: number, voucherItemId: number) {
        if (voucherId == null || voucherItemId == null) return;

        return this.http.delete(`${this.apiUrl}DeleteVoucherItem/${voucherId}/${voucherItemId}`)
            .pipe(
                map((resp: any) => { return resp })
            );
    }
    //Voucher Item - end

    //Download Voucher Report
    exportVoucherReport(fromDate: string, toDate: string, warehouseId: number, voucherCode: string, isFinish: boolean) {
        voucherCode = this.helper.encodeURIComponent(voucherCode);

        let url = `${this.apiUrl}ExportVoucherReport/${fromDate}/${toDate}/${warehouseId}/${isFinish}?voucherCode=${voucherCode}`;
        return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
            .pipe(
                map((res: any) => { return res })
            );
    }
}