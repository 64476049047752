import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { VoucherBaseService } from './voucherBase.service';

import { environment } from '../../../environments/environment';
import { Helper } from '../helpers/helper';

@Injectable({ providedIn: 'root' })
export class TransferLocationService extends VoucherBaseService {
    // private apiUrl: string = `${environment.apiUrl}Web/ChuyenViTri/`;

    constructor(
        protected http: HttpClient,
        protected helper: Helper) {
        super(http, helper);
        this.apiUrl = `${environment.apiUrl}Web/ChuyenViTri/`;
    }

    delete(voucherId: number) {
        if (voucherId == null) return;

        return this.http.delete(`${this.apiUrl}${voucherId}`)
            .pipe(
                map((resp: any) => { return resp })
            );
    }

    setFinish(voucherId: number, isFinish: boolean) {
        return this.http.get(`${this.apiUrl}SetFinish/${voucherId}/${isFinish}`)
            .pipe(
                map((resp: any) => { return { voucher: resp.voucher, message: resp.message } })
            );
    }

    // //Download Voucher Report
    // exportVoucherReport(fromDate: string, toDate: string, warehouseId: number, voucherCode: string, isFinish: boolean) {
    //     if (voucherCode == null) voucherCode = '';

    //     let url = `${this.apiUrl}ExportVoucherReport/${fromDate}/${toDate}/${warehouseId}/${isFinish}?voucherCode=${voucherCode}`;
    //     return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
    //         .pipe(
    //             map((res: any) => { return res })
    //         );
    // }
}