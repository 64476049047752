import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TokenStorage } from '../helpers/token.storage';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private apiToken: string = `${environment.apiUrl}token`;
    private client_id: string = 'ngAuthApp';

    constructor(
        private http: HttpClient,
        private tokenStorage: TokenStorage) { }

    login(username: string, password: string) {
        const body = new URLSearchParams();
        body.set('username', username);
        body.set('password', password);
        body.set('grant_type', 'password')
        body.set('client_id', this.client_id);

        return this.http.post<any>(this.apiToken,
            body.toString(),
            { headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded") })
            .pipe(map(user => {
                if (user && user.access_token) {
                    this.tokenStorage.setCurrentUser(user);
                }

                return user;
            }));
    }

    refreshToken() {
        return this.tokenStorage
            .getRefreshToken().pipe(
                switchMap((refreshToken: string) => {
                    const body = new URLSearchParams();
                    body.set('refresh_token', refreshToken);
                    body.set('grant_type', 'refresh_token');
                    body.set('client_id', this.client_id);

                    return this.http.post<any>(this.apiToken,
                        body.toString(),
                        { headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded") });
                }),
                map(newToken => {
                    this.saveAccessData(newToken);
                    return newToken;
                }),
                catchError(err => {
                    return of(err);
                })
            );
    }

    private saveAccessData(newToken: any) {
        this.tokenStorage
            .setAccessToken(newToken.access_token)
            .setRefreshToken(newToken.refresh_token);
    }
}