import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter, AfterViewInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Texts } from '../../../core/helpers/constant'

@Component({
  selector: 'mat-multi-select',
  template: `
    <mat-form-field>
      <mat-select #multiSelect [ngModel]="value" [multiple]="true" [required]="required" [disabled]="disabled"
                  [placeholder]="placeholder" (selectionChange)="onSelectionChange($event)">
        <mat-option>
          <ngx-mat-select-search [formControl]="itemFilterCtrl" [showToggleAllCheckbox]="showToggleAllCheckbox"
                                 [placeholderLabel]="placeholderLabel"
                                 [noEntriesFoundLabel]="noEntriesFoundLabel"
                                 (toggleAll)="toggleSelectAll($event)"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let option of filteredData | async" [value]="selectedValue(option)">
          {{ displayName(option) }}
        </mat-option>
      </mat-select>
      <button type="button" mat-button *ngIf="!hideClear && value != null && value.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  `,
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MatMultiSelectComponent),
    }
  ]
})
export class MatMultiSelectComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('multiSelect') multiSelect: MatSelect;
  @Input() required: boolean = false;
  @Input() hideClear: boolean = false;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() displayMemberPath: string;
  @Input() showToggleAllCheckbox: boolean = false;
  // @Input() selectedValuePath: string; //not implemented
  selectedValuePath: string;

  private _dataSource: any[];

  @Input() set dataSource(value: any[]) {
    this._dataSource = value;
    if (value != null)
      this.filteredData.next(value.slice());
  }
  get dataSource(): any[] {
    return this._dataSource;
  }

  @Input() placeholderLabel: string = Texts.enterKeyword;
  @Input() noEntriesFoundLabel: string = Texts.notFound;

  @Output() selectionChange = new EventEmitter();

  value: any;
  itemFilterCtrl: FormControl = new FormControl();
  filteredData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  private onChange: (val: boolean) => void;
  private onTouched: () => void;

  ngOnInit() {
    if (this.hideClear.toString() === "")
      this.hideClear = true;

    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this._filterData();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayName(item: any) {
    if (this.displayMemberPath != null && this.displayMemberPath != '')
      return item[this.displayMemberPath];

    return item;
  }

  selectedValue(item: any) {
    if (this.selectedValuePath != null && this.selectedValuePath != '')
      return item[this.selectedValuePath];

    return item;
  }

  onSelectionChange(item: any) {
    this.value = item ? item.value : item;
    this.onChange(this.value);
    this.selectionChange.emit(this.value);
    this.onTouched();
  }
  
  toggleSelectAll(selectAllValue: boolean) {
    this.filteredData.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          // this.cFormControl.patchValue(val);
          this.value = val;
        } else {
          // this.cFormControl.reset();
          this.value = null;
        }
        
        this.onSelectionChange(this.value);
      });
  }

  clear(event: any) {
    this.value = null;
    this.onSelectionChange(this.value);

    //Propagation means bubbling up to parent elements or capturing down to child elements.
    if (event != null)
      event.stopPropagation();
  }

  /**
   * Sets the initial value after the filteredData are loaded initially
   */
  protected setInitialValue() {
    if (this.selectedValuePath != null && this.selectedValuePath != '') {
      this.filteredData
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          // setting the compareWith property to a comparison function
          // triggers initializing the selection according to the initial value of
          // the form control (i.e. _initializeSelection())
          // this needs to be done after the filteredData are loaded initially
          // and after the mat-option elements are available
          this.multiSelect.compareWith = (a: any, b: any) => a && b && a[this.selectedValuePath] === b[this.selectedValuePath];
        });
    }
  }

  protected _filterData() {
    if (!this.dataSource) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredData.next(this.dataSource.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredData.next(
      this.dataSource.filter(s => s[this.displayMemberPath] && s[this.displayMemberPath].toLowerCase().indexOf(search) > -1)
    );
  }
}
