import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Texts } from '../../../core/helpers/constant'

@Component({
  selector: 'mat-single-select',
  template: `
    <mat-form-field>
      <mat-select #singleSelect [ngModel]="value" [required]="required" [disabled]="disabled"
                  [placeholder]="placeholder" (selectionChange)="selectionChanged($event)">
        <mat-option>
          <ngx-mat-select-search [formControl]="itemFilterCtrl"
                                 [placeholderLabel]="placeholderLabel"
                                 [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let option of filteredData | async" [value]="selectedValue(option)">
          {{ displayName(option) }}{{ displayName2(option) }}
        </mat-option>
      </mat-select>
      <button type="button" mat-button *ngIf="!hideClear && value" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MatSingleSelectComponent),
    }
  ]
})
export class MatSingleSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('singleSelect') singleSelect: MatSelect;
  @Input() required: boolean = false;
  @Input() hideClear: boolean = false;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() selectedValuePath: string;
  @Input() displayMemberPath: string;
  @Input() displayMemberPath2: string;

  private _dataSource: any[];

  @Input() set dataSource(value: any[]) {
    this._dataSource = value;
    if (value != null)
      this.filteredData.next(value.slice());
  }
  get dataSource(): any[] {
    return this._dataSource;
  }

  @Input() placeholderLabel: string = Texts.enterKeyword;
  @Input() noEntriesFoundLabel: string = Texts.notFound;

  @Output() selectionChange = new EventEmitter();

  value: any;
  itemFilterCtrl: FormControl = new FormControl();
  filteredData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  private onChange: (val: boolean) => void;
  private onTouched: () => void;

  ngOnInit() {
    if (this.hideClear.toString() === "")
      this.hideClear = true;

    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this._filterData();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayName(item: any) {
    if (this.displayMemberPath != null && this.displayMemberPath != '')
      return item[this.displayMemberPath];

    return item;
  }

  displayName2(item: any) {
    if (this.displayMemberPath2 != null && this.displayMemberPath2 != '') {
      let str = item[this.displayMemberPath2];
      if (str == null || str == '') return str;
      return ' - ' + str;
    }

    return '';
  }

  selectedValue(item: any) {
    if (this.selectedValuePath != null && this.selectedValuePath != '')
      return item[this.selectedValuePath];

    return item;
  }

  selectionChanged(item: any) {
    this.value = item ? item.value : item;
    this.onChange(this.value);
    this.selectionChange.emit(this.value);
    this.onTouched();
  }

  clear(event: any) {
    this.value = null;
    this.selectionChanged(this.value);

    //Propagation means bubbling up to parent elements or capturing down to child elements.
    if (event != null)
      event.stopPropagation();
  }

  protected _filterData() {
    if (!this.dataSource) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredData.next(this.dataSource.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredData.next(
      this.dataSource.filter(s => s[this.displayMemberPath] && s[this.displayMemberPath].toLowerCase().indexOf(search) > -1
        || (this.displayMemberPath2 != null && s[this.displayMemberPath2] && s[this.displayMemberPath2].toLowerCase().indexOf(search) > -1))
    );
  }
}
