import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import $ from 'jquery';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';

import { ToastrService } from 'ngx-toastr';
import { Messages, MenuTexts, ItemsPerPage, DateFormats, Texts, Sensitivity, ContextMenuTexts, ReadMoreMaxLength, VoucherNoteMaxLength } from './constant';
import { TokenStorage } from './token.storage';

@Injectable({ providedIn: 'root' })
export class Helper {
    constructor(
        public dialog: MatDialog,
        private toastr: ToastrService,
        private tokenStorage: TokenStorage
    ) { }

    // static digit = {
    //     decimalTwoPrecision: "1.2-2"
    // };

    readonly itemsPerPage = ItemsPerPage;
    readonly notAllowd = Messages.notAllowed;
    readonly texts = Texts;
    readonly menuTexts = MenuTexts;
    readonly contextMenuTexts = ContextMenuTexts;
    readonly readMoreMaxLength = ReadMoreMaxLength;
    readonly voucherNoteMaxLength = VoucherNoteMaxLength;

    formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    isNullOrEmpty(str: string): boolean {
        return str == null || str === '';
    }

    formatNumber(num: number) {
        if (num == null || num === 0) return '';
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    formatFixedNumber(num: number, fixed: number) {
        if (num == null || num === 0) return '';
        return num.toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    round(num: number, fixed: number = 3) {
        let scale: number = 1;
        for (let i = 1; i <= fixed; i++)
            scale *= 10;

        return Math.round((num + Number.EPSILON) * scale) / scale;
    }

    encodeURIComponent(str: string) {
        return str == null ? '' : encodeURIComponent(str);
    }

    ignoreNull(num: number): number {
        if (num == null) return 0;
        return num;
    }

    // crudErrorMsg(toastr: ToastrService, status: number, textFor409: string = null) {
    //     if (status === 400)
    //         toastr.warning(Messages.mandatory);
    //     else if (status === 404)
    //         toastr.warning(Messages.notFound);
    //     else if (status === 409)
    //         toastr.warning(textFor409);
    //     else if (status === 500)
    //         toastr.error(Messages.error500);
    // }

    showSuccess(msg: string) {
        if (msg != null && msg != '')
            this.toastr.success(msg);
    }

    showInfo(msg: string) {
        if (msg != null && msg != '')
            this.toastr.info(msg);
    }

    showWarning(msg: string) {
        if (msg != null && msg != '')
            this.toastr.warning(msg);
    }

    showError(e: any) {
        if (e != null) {
            if (e.status === 400 || e.status === 403 || e.status === 409) {
                if (e.error != null && e.error.message != null && e.error.message !== '') {
                    this.toastr.warning(e.error.message);
                    return;
                }
            }
        }

        let msg = Messages.error500;
        if (e != null) msg += " (" + e.status + ")";
        this.toastr.error(msg);
    }

    showErrorStr(errorStr: string) {
        this.toastr.error(errorStr);
    }

    loading(show: boolean) {
        var spinner = $("#spinner");
        if (show) spinner.show();
        else spinner.hide();
    }

    // find(array: any, key: string, value: any) {
    //     for (let i in array) {
    //         if (array[i][key] == value)
    //             return array[i];
    //     }
    //     return null;
    // }

    convertDate(dateStr: string, format: string): Date {
        if (dateStr == null || dateStr == '') return null;

        let date = null;

        if (format == null) date = new Date(dateStr);
        else {
            if (format.toLowerCase() == 'dd/mm/yyyy') {
                let pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                date = new Date(dateStr.replace(pattern, '$3-$2-$1T00:00:00'));
            }
        }

        if (date == null) date = new Date(dateStr);

        return date;
    }

    today() {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        return moment(today);
    }

    dateToJSON(d: Date | string, format: string = DateFormats.yyyyMMddJSON) {
        // if (d == null) return null;
        // return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        return moment(d).format(format);
    }

    toDateVN(d: Date | string) {
        if (d === null) return "";
        return moment(d).format(DateFormats.ddMMyyyy);
    }

    toDateTimeVN(d: Date | string) {
        if (d === null) return "";
        return moment(d).format(DateFormats.ddMMyyyyHHmm);
    }

    toFullDateTimeVN(d: Date | string) {
        if (d === null) return "";
        return moment(d).format(DateFormats.ddMMyyyyHHmmss);
    }

    toNull(obj: any) {
        return obj == null || obj === '' ? null : obj;
    }

    compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
        let a1 = a, b1 = b;

        if (!((typeof (a) === "number" || typeof (a) === "boolean") &&
            typeof (b) === "number" || typeof (b) === "boolean")) {
            a1 = a != null ? a.toString().toLowerCase() : a;
            b1 = b != null ? b.toString().toLowerCase() : b;
        }

        if (a1 == null) a1 = "";
        if (b1 == null) b1 = "";

        return (a1 < b1 ? -1 : 1) * (isAsc ? 1 : -1);
    }

    //Sensitivity options
    //base: a = b => false
    //accent: a = b, a = á => false
    //case: a = b, a = A => false
    //https://stackoverflow.com/questions/2140627/how-to-do-case-insensitive-string-comparison
    equals(a: any, b: any, sensitivity: string = Sensitivity.accent) {
        return typeof a === 'string' && typeof b === 'string'
            ? a.localeCompare(b, undefined, { sensitivity: sensitivity }) === 0
            : a === b;
    }

    getRowNo(currentPage: number, i: number, itemsPerPage: number = ItemsPerPage) {
        return ((currentPage == null ? 0 : currentPage - 1) * itemsPerPage) + i + 1;
    }

    setIndex(data: any[]) {
        if (data == null || data.length === 0) return;

        // if (data[0].hasOwnProperty('index'))
        for (let i = 0; i < data.length; i++)
            data[i].index = i + 1;
    }

    download(resp: HttpResponse<Response>) {
        const contentDisposition = resp.headers.get('content-disposition') || '';
        const matches = /filename="([^;]+)"/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();

        if (navigator.appVersion.toString().indexOf('.NET') > 0)
            window.navigator.msSaveBlob(resp.body, fileName);
        else {
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(resp.body);
            if (fileName)
                downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }

    //HttpErrorResponse
    getHttpErrorResponse(e: any, callback: any) {
        if (e instanceof HttpErrorResponse && e.error instanceof Blob && e.error.type === "application/json") {
            let reader = new FileReader();
            reader.onload = (e: Event) => {
                try {
                    let msg = JSON.parse((<any>e.target).result);
                    callback(msg.message);
                } catch (e1) { }
            };
            reader.readAsText(e.error);
        }
    }

    isMobileScreen() {
        return window.innerWidth < 768;
    }

    isMenuToggled() {
        return $(".sidebar").hasClass("toggled");
    }

    toggleMenu() {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");

        if (this.isMenuToggled())
            this.hideSidebarDropdown();
    }

    hideSidebarDropdown() {
        $(".sidebar").find('li.nav-item.dropdown.show, div.dropdown-menu.show').removeClass('show');
    }

    canAccess(featureCode: string, permissionCode: string): boolean {
        let ps = this.tokenStorage.getPermissions();
        if (ps) {
            let p = ps.find(s => this.equals(s.featureCode, featureCode) && this.equals(s.permissionCode, permissionCode));
            if (p) return p.value;
        }
        return false;
    }
}