import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material';
import * as moment from 'moment';

import { IWarehouseCellItemService } from '../../../core/services/iWarehouseCellItem.service';

import { Helper } from '../../../core/helpers/helper';
import { DateFormats } from '../../../core/helpers/constant';
import { WarehouseCellItem } from '../../../core/models/warehouseCellItem';
import { WarehouseCellItemLot } from '../../../core/models/WarehouseCellItemLot';

import { ItemLotLogDialog } from '../../voucher/item-lot/item-lot-log.dialog';
import { ItemLotInventoryDialog } from '../../voucher/item-lot/item-lot-inv.dialog';

@Component({
  templateUrl: 'wh-map-cell-item.dialog.html',
})
export class WhMapCellItemDialog implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  service: IWarehouseCellItemService;

  items: WarehouseCellItem[];
  sortedItems: WarehouseCellItem[];
  filterItem: string = "";

  itemDetails: WarehouseCellItemLot[];
  sortedItemDetails: WarehouseCellItemLot[];
  filterItemDetail: string = "";

  itemLots: WarehouseCellItemLot[];
  sortedItemLots: WarehouseCellItemLot[];
  filterItemLot: string = "";
  cellCode: string;

  itemFooter: WarehouseCellItem;
  itemDetailFooter: WarehouseCellItemLot;
  itemLotFooter: WarehouseCellItemLot;

  selectedItemCode: string;
  selectedItemDetailId: number;
  selectedItemLotId: number;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<WhMapCellItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helper: Helper) { }

  ngOnInit() {
    this.itemFooter = new WarehouseCellItem();
    this.itemDetailFooter = new WarehouseCellItemLot();
    this.itemLotFooter = new WarehouseCellItemLot();

    this.service = this.data.service as IWarehouseCellItemService;
    this.loadInfo();
  }

  private loadInfo() {
    if (this.data == null || this.service == null) return;

    this.helper.loading(true);
    this.service.getWarehouseCellItem(this.data.cellId, null).subscribe(resp => {
      this.items = resp.items as WarehouseCellItem[];
      this.sortedItems = this.items.slice();
      this.getItemTotal(this.items);

      if (this.items != null && this.items.length > 0)
        this.cellCode = this.items[0].cellCode;

      this.itemLots = resp.itemLots as WarehouseCellItemLot[];
      this.sortedItemLots = this.itemLots.slice();
      this.getItemLotTotal(this.itemLots, this.itemLotFooter);

      if (this.items.length > 0) {
        this.viewLotByItem(this.items[0].itemCode, false);
      }

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  exportWarehouseCellItem() {
    if (this.data == null || this.service == null) return;

    this.helper.loading(true);
    this.service.exportWarehouseCellItem(this.data.cellId, null).subscribe(resp => {
      this.helper.download(resp);

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);

      let that = this;
      this.helper.getHttpErrorResponse(ex, function (msg: string) {
        that.helper.showWarning(msg);
      });
    });
  }

  //event
  //Item
  itemSortChange(sort: Sort) {
    if (this.items == null) return;

    const temps = this.items.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedItems = temps;
      return;
    }

    this.sortedItems = temps.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'cellCode': return this.helper.compare(a.cellCode, b.cellCode, isAsc);
        case 'qty': return this.helper.compare(a.qty, b.qty, isAsc);
        // case 'netWeight': return this.helper.compare(a.netWeight, b.netWeight, isAsc);
        case 'itemCode': return this.helper.compare(a.itemCode, b.itemCode, isAsc);
        case 'itemName': return this.helper.compare(a.itemName, b.itemName, isAsc);
        default: return 0;
      }
    });
  }

  itemFilterCallback(value: WarehouseCellItem[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }
    return value.filter(item => (item.itemCode != null && item.itemCode.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.itemName != null && item.itemName.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
      (item.qty != null && item.qty.toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
      // (item.netWeight != null && item.netWeight.toString().toLowerCase().indexOf(q.toLowerCase()) > -1));
  }

  getIndexItem(item: WarehouseCellItem) {
    if (item == null || this.sortedItems == null)
      return null;

    let idx = this.sortedItems.findIndex(s => s.cellId === item.cellId);
    return idx + 1;
  }

  rowItemClick(itemCode: string) {
    this.selectedItemCode = itemCode;
  }

  private getItemTotal(items: WarehouseCellItem[]) {
    if (items == null || items.length === 0) {
      this.itemFooter.cellId = this.itemFooter.qty = this.itemFooter.netWeight = null;
      return;
    }

    this.itemFooter.cellId = items.length;
    this.itemFooter.qty = this.helper.round(items.reduce((a, b) => a + b.qty, 0));
    this.itemFooter.netWeight = this.helper.round(items.reduce((a, b) => a + b.netWeight, 0));
  }

  //ItemDetail (ItemLot)
  viewLotByItem(itemCode: string, clearFilter: boolean = true) {
    if (clearFilter) this.filterItemDetail = "";

    this.selectedItemCode = itemCode;
    this.itemDetails = this.itemLots.filter(s => this.helper.equals(s.itemCode, itemCode));
    this.sortedItemDetails = this.itemDetails.slice();

    this.getItemLotTotal(this.itemDetails, this.itemDetailFooter);
  }

  itemDetailSortChange(sort: Sort) {
    if (this.itemDetails == null) return;

    const temps = this.itemDetails.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedItemDetails = temps;
      return;
    }

    this.sortedItemDetails = this.sortItemLot(sort, temps);
  }

  getIndexItemDetail(item: WarehouseCellItemLot) {
    if (item == null || this.sortedItemDetails == null)
      return null;

    let idx = this.sortedItemDetails.findIndex(s => s.itemLotId === item.itemLotId);
    return idx + 1;
  }

  rowItemDetailClick(itemLotId: number) {
    this.selectedItemDetailId = itemLotId;
  }

  //ItemLot
  itemLotSortChange(sort: Sort) {
    if (this.itemLots == null) return;

    const temps = this.itemLots.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedItemLots = temps;
      return;
    }

    this.sortedItemLots = this.sortItemLot(sort, temps);
  }

  private sortItemLot(sort: Sort, data: WarehouseCellItemLot[]) {
    return data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'maLot': return this.helper.compare(a.maLot, b.maLot, isAsc);
        // case 'netWeight': return this.helper.compare(a.netWeight, b.netWeight, isAsc);
        case 'itemCode': return this.helper.compare(a.itemCode, b.itemCode, isAsc);
        case 'itemName': return this.helper.compare(a.itemName, b.itemName, isAsc);
        case 'ngayNhapKho': return this.helper.compare(a.ngayNhapKho, b.ngayNhapKho, isAsc);
        default: return 0;
      }
    });
  }

  itemLotFilterCallback(value: WarehouseCellItemLot[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }
    return value.filter(item => (item.maLot != null && item.maLot.toLowerCase().indexOf(q.toLowerCase()) > -1)
      // || (item.netWeight != null && item.netWeight.toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.itemCode != null && item.itemCode.toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.itemName != null && item.itemName.toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.ngayNhapKho != null && moment(item.ngayNhapKho).format(DateFormats.ddMMyyyyHHmm).indexOf(q) > -1));
  }

  getIndexItemLot(item: WarehouseCellItemLot) {
    if (item == null || this.sortedItemLots == null)
      return null;

    let idx = this.sortedItemLots.findIndex(s => s.itemLotId === item.itemLotId);
    return idx + 1;
  }

  private getItemLotTotal(items: WarehouseCellItemLot[], footer: WarehouseCellItemLot) {
    if (items == null || items.length === 0) {
      footer.itemLotId = footer.netWeight = null;
      return;
    }

    footer.itemLotId = items.length;
    footer.netWeight = this.helper.round(items.reduce((a, b) => a + b.netWeight, 0));
  }

  rowItemLotClick(itemLotId: number) {
    this.selectedItemLotId = itemLotId;
  }

  //-----------------------------------------------------------------------
  // Dialog
  //-----------------------------------------------------------------------
  viewItemLotLog(item: WarehouseCellItemLot, type: number) {
    if (type === 2)
      this.rowItemDetailClick(item.itemLotId);
    else if (type === 3)
      this.rowItemLotClick(item.itemLotId);

    this.dialog.open(ItemLotLogDialog, {
      disableClose: true,
      data: item.maLot
    });
  }

  //-----------------------------------------------------------------------
  //searchItemLot Inventory
  //-----------------------------------------------------------------------
  searchItemLotInventory(itemCode: string, selectedId: number, type: number) {
    // if (type === 1)
    //     this.selectedItemCode = itemCode;
    // else if (type === 2)
    //     this.selectedItemDetailId = selectedId;
    if (type === 3)
        this.selectedItemLotId = selectedId;

    if (this.data == null) return;

    this.dialog.open(ItemLotInventoryDialog, {
      disableClose: true,
      data: { service: this.service, warehouseId: this.data.warehouseId, itemCode: itemCode }
    });
  }
}