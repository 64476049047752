import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { IWarehouseCellItemService } from './iWarehouseCellItem.service';
import { IVoucherService } from './iVoucher.service';

import { Helper } from '../helpers/helper';
import { Warehouse } from '../models/warehouse';
import { ItemLotSearching } from '../models/itemLotSearching';
import { User } from '../models/user';
import { Voucher } from '../models/voucher';
import { VoucherItem } from '../models/voucherItem';

export abstract class VoucherBaseService implements IVoucherService, IWarehouseCellItemService {
    protected apiUrl: string;

    constructor(
        protected http: HttpClient,
        protected helper: Helper) { }

    //Warehouse
    getWarehouse() {
        return this.http.get(`${this.apiUrl}GetWarehouse`)
            .pipe(
                map((resp: Warehouse[]) => { return resp })
            );
    }

    //voucher
    getList(fromDate: string, toDate: string, warehouseId: number, voucherCode: string, isFinish: boolean) {
        voucherCode = this.helper.encodeURIComponent(voucherCode);

        return this.http.get(`${this.apiUrl}GetList/${fromDate}/${toDate}/${warehouseId}/${isFinish}?voucherCode=${voucherCode}`)
            .pipe(
                map((resp: Voucher[]) => { return resp })
            );
    }

    save(v: Voucher) {
        if (v == null) return;

        return this.http.put(`${this.apiUrl}`, v)
            .pipe(
                map((resp: Voucher) => { return resp })
            );
    }

    setStartStop(voucherId: number, isStarted: boolean) {
        return this.http.get(`${this.apiUrl}SetStartStop/${voucherId}/${isStarted}`)
            .pipe(
                map((resp: string) => { return resp })
            );
    }

    getHandheldUsers(voucherId: number) {
        return this.http.get(`${this.apiUrl}GetHandheldUsers/${voucherId}`)
            .pipe(
                map((resp: User[]) => { return resp })
            );
    }

    updateOperator(voucherId: number, operatorBy: string) {
        operatorBy = this.helper.encodeURIComponent(operatorBy);

        return this.http.get(`${this.apiUrl}UpdateOperator/${voucherId}?operatorBy=${operatorBy}`)
            .pipe(
                map((resp: any) => { return resp })
            );
    }

    //save Item
    saveItem(v: VoucherItem) {
        if (v == null) return;

        return this.http.put(`${this.apiUrl}UpdateVoucherItem`, v)
            .pipe(
                map((resp: number) => { return resp })
            );
    }

    //Voucher Item & Detail
    getVoucherItemDetail(voucherId: number) {
        return this.http.get(`${this.apiUrl}GetVoucherItemDetail/${voucherId}`)
            .pipe(
                map((resp: any) => { return resp })
            );
    }

    //Download Voucher - all ItemLot
    exportVoucherItemLot(voucherId: number) {
        let url = `${this.apiUrl}ExportVoucherItemLot/${voucherId}`;
        return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
            .pipe(
                map((res: any) => { return res })
            );
    }

    //Download Voucher - the Item
    downloadVoucherItem(voucherId: number, voucherItemId: number) {
        let url = `${this.apiUrl}ExportVoucherItem/${voucherId}/${voucherItemId}`;
        return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
            .pipe(
                map((res: any) => { return res })
            );
    }

    //ItemLot Inventory
    searchItemLotInventory(warehouseId: number, itemCode: string) {
        itemCode = this.helper.encodeURIComponent(itemCode);

        return this.http.get(`${this.apiUrl}SearchItemLotInventory/${warehouseId}?itemCode=${itemCode}`)
            .pipe(
                map((res: ItemLotSearching[]) => { return res })
            );
    }

    //WarehouseCell Item
    getWarehouseCellItem(cellId: number, cellCode: string) {
        cellCode = this.helper.encodeURIComponent(cellCode);

        return this.http.get(`${this.apiUrl}GetWarehouseCellItem/${cellId}?cellCode=${cellCode}`)
            .pipe(
                map((res: any) => { return { items: res.items, itemLots: res.itemLots } })
            );
    }

    exportWarehouseCellItem(cellId: number, cellCode: string) {
        cellCode = this.helper.encodeURIComponent(cellCode);

        let url = `${this.apiUrl}ExportWarehouseCellItem/${cellId}?cellCode=${cellCode}`;
        return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
            .pipe(
                map((res: any) => { return res })
            );
    }
    //WarehouseCell Item - end
}