import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Texts } from '../../../core/helpers/constant'

@Component({
  selector: 'mat-filter-input',
  template: `
    <mat-form-field floatLabel="never">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput trim="blur" [(ngModel)]="filter" (ngModelChange)="filterChange.emit(filter)" [placeholder]="placeholder" [disabled]="disabled">
        <button type="button" mat-button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="filter = null; filterChange.emit(filter)">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
  `,
})
export class MatFilterInputComponent {
  @Input() filter: string;
  @Input() placeholder: string = Texts.filter;
  @Input() disabled: boolean;

  @Output() filterChange = new EventEmitter<string>();

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}