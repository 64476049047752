import { Component, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_CHECKBOX_CLICK_ACTION, MatCheckbox } from '@angular/material';

@Component({
  selector: 'mat-checkbox-3state',
  template: `
  <mat-checkbox #matCheckbox [ngModel]="value" (change)="change()" [disabled]="disabled" [color]="color">
    <ng-content></ng-content>
  </mat-checkbox>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatCheckbox3StateComponent),
      multi: true,
    },
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: { clickAction: 'noop' } },
  ],
})
export class MatCheckbox3StateComponent implements ControlValueAccessor {
  @ViewChild('matCheckbox') matCheckbox: MatCheckbox;
  @Input() tape = [null, true, false];
  @Input() color: string;
  @Input() disabled: boolean;

  value?: any;

  private onChange: (val: boolean) => void;
  private onTouched: () => void;

  writeValue(value: any) {
    this.value = value !== undefined ? value : this.tape[0];
    // console.log("w1", value, this.value);
    this.matCheckbox.indeterminate = this.value == null;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  change() {
    this.onChange(this.value = this.tape[(this.tape.indexOf(this.value) + 1) % this.tape.length]);
    this.matCheckbox.indeterminate = this.value == null;
    this.onTouched();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}