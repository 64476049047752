import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { User } from '../models/user';
import { ChangePassword } from '../models/changePassword';
import { FactoryWarehouse } from '../models/factoryWarehouse';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private apiUrl: string = `${environment.apiUrl}Account/`;

    constructor(private http: HttpClient) { }

    getUsers() {
        return this.http.get(this.apiUrl + 'Users')
            .pipe(
                map((res: any) => { return res }),
                // catchError(e => { return throwError(e) })
            );
    }

    save(item: User) {
        return this.http.post(this.apiUrl + "Register", item)
            .pipe(
                map((res: any) => { return res })
            );
    }

    changePassword(item: ChangePassword) {
        return this.http.post(`${this.apiUrl}ChangePassword`, item)
            .pipe(
                map((res: any) => { return res })
            );
    }

    resetPassword(item: any) {
        return this.http.post(this.apiUrl + "ResetPassword", item)
            .pipe(
                map((res: any) => { return res })
            );
    }

    getWarehouseByUser(userName: string) {
        return this.http.get(`${this.apiUrl}GetWarehouseByUser?userName=${userName}`)
            .pipe(
                map((res: any) => { return { warehouses: res.warehouses, showAllWarehouse: res.showAllWarehouse } })
            );
    }

    // delete(ItemTypeId: number) {
    //     return this.http.delete(this.apiUrl + ItemTypeId)
    //         .pipe(
    //             map((res: any) => { return res })
    //         );
    // }
}