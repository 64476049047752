import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
// import { DOCUMENT } from "@angular/platform-browser";
import { Router } from '@angular/router';
import $ from 'jquery';

import { RouteLinks, RoutePaths, FeatureCodes, PermissionCodes } from "../../../core/helpers/constant";
import { Helper } from "../../../core/helpers/helper";
import { TokenStorage } from "../../../core/helpers/token.storage";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit {
  //userName: string;
  readonly routeLinks = RouteLinks;
  readonly routePaths = RoutePaths;
  readonly featureCodes = FeatureCodes;
  // readonly permissionCodes = PermissionCodes;
  dropdownClass = [];

  constructor(
    // @Inject(DOCUMENT) private document: any,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public helper: Helper,
    private tokenStorage: TokenStorage) { }

  ngOnInit() {
    const that = this;
    $(window).resize(function () {
      if (that.helper.isMobileScreen())
        that.helper.hideSidebarDropdown();
    });

    // $("#sidebarToggle").on('click', function (e) {
    //   e.preventDefault();
    //   $("body").toggleClass("sidebar-toggled");
    //   $(".sidebar").toggleClass("toggled");

    //   that.cdr.detectChanges();
    // });

    $('.sidebar .nav-link.dropdown-toggle').on('click', function (e) {
      e.preventDefault();

      if (that.helper.isMobileScreen() || that.helper.isMenuToggled()) {
        let isOpened = $(this).parent().hasClass('show');
        that.helper.hideSidebarDropdown();

        if (!isOpened) {
          $(this).parent().addClass('show');
          $(this).parent().find('.dropdown-menu').addClass('show');
        }
      }
      else {
        $(this).parent().toggleClass('show');
        $(this).parent().find('.dropdown-menu').toggleClass('show');
      }
    });

    $(document).on('click', '.dropdown-menu', function (e) {
      e.preventDefault();
      e.stopPropagation();

      if (that.helper.isMobileScreen() || that.helper.isMenuToggled())
        that.helper.hideSidebarDropdown();
    });
  }

  canAccess(featureCode: string): boolean {
    return this.helper.canAccess(featureCode, PermissionCodes.view);
  }

  showDropdownMenu(routePath: string, featureCode?: string) {
    if (featureCode != null && !this.hasChildFeature(featureCode))
      return 'hidden';

    if ((this.helper.equals(routePath, RoutePaths.voucher) || this.helper.equals(routePath, RoutePaths.warehouse)) &&
      !(this.helper.isMobileScreen() || this.helper.isMenuToggled()))
      return 'show';

    return this.getRoute(routePath) && !(this.helper.isMobileScreen() || this.helper.isMenuToggled()) ? 'show' : '';
  }

  getActive(routePath: string) {
    return this.getRoute(routePath) ? 'active' : '';
  }

  private getRoute(routePath: string) {
    let url = this.router.url;
    let path = url;
    if (url && url.length > 0) {
      if (url.charAt(0) === '/')
        path = url = url.substring(1);

      if (url.length > 0) {
        let paths = url.split('/');
        if (paths.length > 0)
          path = paths[0];
      }
    }
    return this.helper.equals(path, routePath);
  }

  private hasChildFeature(featureCode: string) {
    let ps = this.tokenStorage.getPermissions();
    if (ps) {
      let p = ps.find(s => this.helper.equals(s.featureCode, featureCode));
      if (p) {
        let child = ps.find(s => s.parentId === p.featureId && this.helper.equals(s.permissionCode, PermissionCodes.view) && s.value);
        if (child) return true;
      }
    }
    return false;
  }
}
