
export class Item {
  itemId: number;
  itemCode: string;
  itemName: string;
  warehouseCode: string;
  maDonVi: string;
  tenDonVi: string;
  // created?: Date;
  // createdBy?: string;

  public constructor(init?: Partial<Item>) {
    Object.assign(this, init);
  }
}
