
export class VoucherDetail {
  voucherDetailId: number;
  voucherItemId: number;
  itemLotId: number;
  cellId: number;
  cellId2: number;
  note: string;
  created?: Date;
  createdBy?: string;
  modified?: Date;
  modifiedBy?: string;
  itemLotId2: number;
  created2?: Date;
  createdBy2?: string;
  modified2?: Date;
  modifiedBy2?: string;

  cellCode: string;
  cellCode2: string;
  itemCode: string;
  itemName: string;
  // qrCode: string;
  maLot: string;
  netWeight: number;
  // qrCode2: string;
  maLot2: string;
  netWeight2: number;
  ngayNhapKho: Date;
  voucherCode: string;

  public constructor(init?: Partial<VoucherDetail>) {
    Object.assign(this, init);
  }
}
