import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material';

import { IWarehouseCellItemService } from '../services/iWarehouseCellItem.service';
import { Permission } from './permission';

import { Helper } from './helper';
import { VoucherItem } from '../models/voucherItem';
import { VoucherDetail } from '../models/voucherDetail';

import { ItemLotLogDialog } from '../../modules/voucher/item-lot/item-lot-log.dialog';
import { ItemLotInventoryDialog } from '../../modules/voucher/item-lot/item-lot-inv.dialog';
import { WhMapCellItemDialog } from '../../modules/warehouse/warehouse-map/wh-map-cell-item.dialog';

export class VoucherDetailBase extends Permission {
    items: VoucherItem[];
    sortedItems: VoucherItem[];
    filterItem: string = "";

    itemLots: VoucherDetail[];
    sortedItemLots: VoucherDetail[];
    filterItemLot: string = "";

    details: VoucherDetail[];
    sortedDetails: VoucherDetail[];
    filterDetail: string = "";

    itemFooter: VoucherItem;
    itemLotFooter: VoucherDetail;
    detailFooter: VoucherDetail;

    selectedItemId: number;
    selectedItemLotId: number;
    selectedDetailId: number;

    constructor(
        protected dialog: MatDialog,
        protected helper: Helper,
        protected featureCode: string,
        protected service: IWarehouseCellItemService,
        protected warehouseId: number) {
        super(helper, featureCode);

        this.itemFooter = new VoucherItem();
        this.itemLotFooter = new VoucherDetail();
        this.detailFooter = new VoucherDetail();
    }

    //-----------------------------------------------------------------------
    // Item
    //-----------------------------------------------------------------------
    itemSortChange(sort: Sort) {
        if (this.items == null) return;

        const data = this.items.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedItems = data;
            return;
        }

        this.sortedItems = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'itemCode': return this.helper.compare(a.itemCode, b.itemCode, isAsc);
                case 'itemName': return this.helper.compare(a.itemName, b.itemName, isAsc);
                case 'qty': return this.helper.compare(a.qty, b.qty, isAsc);
                // case 'netWeight': return this.helper.compare(a.netWeight, b.netWeight, isAsc);
                default: return 0;
            }
        });
    }

    getIndex_Item(item: VoucherItem) {
        if (item == null || this.items == null)
            return null;

        let idx = this.items.findIndex(s => s.voucherItemId === item.voucherItemId);
        return idx + 1;
    }

    protected getItemTotal(items: VoucherItem[]) {
        if (items == null || items.length === 0) {
            this.itemFooter.voucherItemId = this.itemFooter.qty = this.itemFooter.netWeight = null;
            return;
        }

        this.itemFooter.voucherItemId = items.length;
        this.itemFooter.requestQty = this.helper.round(items.reduce((a, b) => a + b.requestQty, 0));
        this.itemFooter.requestWeight = this.helper.round(items.reduce((a, b) => this.helper.ignoreNull(a) + this.helper.ignoreNull(b.requestWeight), 0));
        this.itemFooter.qty = this.helper.round(items.reduce((a, b) => a + b.qty, 0));
        this.itemFooter.netWeight = this.helper.round(items.reduce((a, b) => a + b.netWeight, 0));
    }

    //-----------------------------------------------------------------------
    // ItemLot
    //-----------------------------------------------------------------------
    viewLotByItem(voucherItemId: number, clearFilter: boolean = true) {
        if (clearFilter) this.filterItemLot = "";

        this.selectedItemId = voucherItemId;
        this.itemLots = this.details.filter(s => s.voucherItemId === voucherItemId);
        this.sortedItemLots = this.itemLots.slice();

        this.getItemLotTotal(this.itemLotFooter, this.itemLots);
    }

    itemLotSortChange(sort: Sort) {
        if (this.itemLots == null) return;

        const data = this.itemLots.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedItemLots = data;
            return;
        }

        this.sortedItemLots = this.sortItemLot(sort, data);
    }

    private sortItemLot(sort: Sort, data: VoucherDetail[]) {
        return data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'maLot': return this.helper.compare(a.maLot, b.maLot, isAsc);
                // case 'netWeight': return this.helper.compare(a.netWeight, b.netWeight, isAsc);
                case 'cellCode': return this.helper.compare(a.cellCode, b.cellCode, isAsc);
                case 'cellCode2': return this.helper.compare(a.cellCode2, b.cellCode2, isAsc);
                case 'created': return this.helper.compare(a.created, b.created, isAsc);
                case 'createdBy': return this.helper.compare(a.createdBy, b.createdBy, isAsc);
                case 'maLot2': return this.helper.compare(a.maLot2, b.maLot2, isAsc);
                case 'created2': return this.helper.compare(a.created2, b.created2, isAsc);
                case 'createdBy2': return this.helper.compare(a.createdBy2, b.createdBy2, isAsc);
                case 'itemCode': return this.helper.compare(a.itemCode, b.itemCode, isAsc);
                default: return 0;
            }
        });
    }

    getIndex_ItemLot(item: VoucherDetail) {
        if (item == null || this.itemLots == null)
            return null;

        let idx = this.itemLots.findIndex(s => s.voucherDetailId === item.voucherDetailId);
        return idx + 1;
    }

    protected getItemLotTotal(item: VoucherDetail, items: VoucherDetail[]) {
        if (items == null || items.length === 0) {
            item.itemLotId = item.netWeight = item.itemLotId2 = null;
            return;
        }

        item.itemLotId = items.filter(s => s.itemLotId != null).length;
        item.netWeight = this.helper.round(items.reduce((a, b) => a + b.netWeight, 0));
        item.itemLotId2 = items.filter(s => s.itemLotId2 != null).length;
    }

    rowItemLotClick(item: VoucherDetail) {
        if (item != null)
            this.selectedItemLotId = item.voucherDetailId;
    }

    //-----------------------------------------------------------------------
    // Detail
    //-----------------------------------------------------------------------
    detailSortChange(sort: Sort) {
        if (this.details == null) return;

        const data = this.details.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedDetails = data;
            return;
        }

        this.sortedDetails = this.sortItemLot(sort, data);
    }

    getIndex_Detail(item: VoucherDetail) {
        if (item == null || this.details == null)
            return null;

        let idx = this.details.findIndex(s => s.voucherDetailId === item.voucherDetailId);
        return idx + 1;
    }

    rowDetailClick(item: VoucherDetail) {
        if (item != null)
            this.selectedDetailId = item.voucherDetailId;
    }

    //-----------------------------------------------------------------------
    // Dialog
    //-----------------------------------------------------------------------
    viewItemLotLog(item: VoucherDetail, type: number) {
        if (type === 2)
            this.rowItemLotClick(item);
        else if (type === 3)
            this.rowDetailClick(item);

        this.dialog.open(ItemLotLogDialog, {
            disableClose: true,
            data: item.maLot
        });
    }

    //-----------------------------------------------------------------------
    //WarehouseCell Item
    //-----------------------------------------------------------------------
    viewWarehouseCellItem(cellId: number) {
        if (cellId == null) return;

        this.dialog.open(WhMapCellItemDialog, {
            disableClose: true,
            data: { service: this.service, cellId: cellId }
        });
    }

    //-----------------------------------------------------------------------
    //searchItemLot Inventory
    //-----------------------------------------------------------------------
    searchItemLotInventory(itemCode: string, selectedId: number, type: number) {
        // if (type === 1)
        //     this.selectedItemId = selectedId;
        // else if (type === 2)
        //     this.selectedItemLotId = selectedId;
        if (type === 3)
            this.selectedDetailId = selectedId;

        this.dialog.open(ItemLotInventoryDialog, {
            disableClose: true,
            data: { service: this.service, warehouseId: this.warehouseId, itemCode: itemCode }
        });
    }
}