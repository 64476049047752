import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatFormFieldModule, MatNativeDateModule, MatButtonModule, MatInputModule, MatAutocompleteModule,
  MatSelectModule, MatDatepickerModule, MatIconModule, MatCheckboxModule, MatRadioModule, MatSortModule, MatDialogModule,
  MatTabsModule, MatCardModule, MatTooltipModule, MatChipsModule, MatBadgeModule, MatProgressBarModule
} from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxNumberFormatModule } from 'ngx-number-format';
import { ContextMenuModule } from 'ngx-contextmenu';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { InputTrimModule } from 'ng2-trim-directive';

import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';
// import { MatSelectSearchComponent } from './components/mat-select-search.component';

import { ConfirmDialog } from './modals/confirm.dialog';
import { AccountChangePasswordDialog } from '../system/account/account-change-password.dialog';
import { ItemLotLogDialog } from "../voucher/item-lot/item-lot-log.dialog";
import { ItemLotInventoryDialog } from "../voucher/item-lot/item-lot-inv.dialog";
import { WhMapCellItemDialog } from '../warehouse/warehouse-map/wh-map-cell-item.dialog';
import { ReceiveInventoryDetailDialog } from '../voucher/receive-inventory/rcv-inv-detail.dialog';
import { ReceiveInventoryRecordDetailDialog } from '../voucher/receive-inventory-record/rcv-inv-record-detail.dialog';
import { IssueInventoryDetailDialog } from '../voucher/issue-inventory/iss-inv-detail.dialog';
import { IssueInventoryRecordDetailDialog } from '../voucher/issue-inventory-record/iss-inv-record-detail.dialog';
import { TransferLocationDetailDialog } from '../voucher/transfer-location/trf-loc-detail.dialog';
import { InventoryDetailDialog } from '../voucher/inventory/inventory-detail.dialog';

import { MatFilterInputComponent } from './components/mat-filter-input.component';
import { MatSingleSelectComponent } from './components/mat-single-select.component';
import { MatMultiSelectComponent } from './components/mat-multi-select.component';
import { MatCheckbox3StateComponent } from './components/mat-checkbox-3state.component';
import { ReadMoreComponent } from './components/read-more.component';
import { StringFilterPipe } from '../shared/pipes/string-filter.pipe';
import { MY_DATE_FORMATS } from '../../core/helpers/constant';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // // FlexLayoutModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    // MatNativeDateModule,
    MatButtonModule,
    MatInputModule,
    // MatAutocompleteModule,
    MatSelectModule,
    MatTooltipModule,
    // MatDatepickerModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatCheckboxModule,
    MatSortModule,
    // MatTableModule,
    MatDialogModule,
    MatTabsModule,
    MatProgressBarModule,
    // NgxPaginationModule,
    VirtualScrollerModule,
    ContextMenuModule,
    InputTrimModule
  ],
  declarations: [
    LayoutComponent,
    NavigationComponent,
    // MatSelectSearchComponent,
    ConfirmDialog,
    AccountChangePasswordDialog,
    ItemLotLogDialog,
    ItemLotInventoryDialog,
    WhMapCellItemDialog,
    ReceiveInventoryDetailDialog,
    ReceiveInventoryRecordDetailDialog,
    IssueInventoryDetailDialog,
    IssueInventoryRecordDetailDialog,
    TransferLocationDetailDialog,
    InventoryDetailDialog,
    MatFilterInputComponent,
    MatSingleSelectComponent,
    MatMultiSelectComponent,
    MatCheckbox3StateComponent,
    ReadMoreComponent,
    StringFilterPipe
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    // FlexLayoutModule,
    // MatFormFieldModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    NavigationComponent,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    MatBadgeModule,
    NgxPaginationModule,
    NgxNumberFormatModule,
    MatFilterInputComponent,
    MatSingleSelectComponent,
    MatMultiSelectComponent,
    MatCheckbox3StateComponent,
    ReadMoreComponent,
    StringFilterPipe,
    VirtualScrollerModule,
    ContextMenuModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    InputTrimModule
  ],
  entryComponents: [
    ConfirmDialog,
    AccountChangePasswordDialog,
    ItemLotLogDialog,
    ItemLotInventoryDialog,
    WhMapCellItemDialog,
    ReceiveInventoryDetailDialog,
    ReceiveInventoryRecordDetailDialog,
    IssueInventoryDetailDialog,
    IssueInventoryRecordDetailDialog,
    TransferLocationDetailDialog,
    InventoryDetailDialog
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ]
})
export class SharedModule { }
