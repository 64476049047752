
export class WarehouseCellItem {
  cellId: number;
  cellCode: string;
  cellCodeFull: string;
  itemCode: string;
  itemName: string;
  qty: number;
  netWeight: number;

  public constructor(init?: Partial<WarehouseCellItem>) {
    Object.assign(this, init);
  }
}
