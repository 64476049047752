import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SysFeaturePermissionUdt } from '../models/sysFeaturePermissionUdt';
import { VoucherType } from '../models/voucherType';

@Injectable()
export class TokenStorage {
  public setCurrentUser(userInfo: any) {
    localStorage.setItem("currentUser", JSON.stringify(userInfo));
    return this;
  }

  public getCurrentUser(): any {
    let currUser = JSON.parse(localStorage.getItem("currentUser"));
    return currUser;
  }

  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): Observable<string> {
    let currUser = this.getCurrentUser();
    const token: string = currUser.access_token;
    return of(token);
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): Observable<string> {
    let currUser = this.getCurrentUser();
    let token: string;
    if (currUser) token = <string>currUser.refresh_token;
    return of(token);
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    let currUser = this.getCurrentUser();
    currUser.access_token = token;
    this.setCurrentUser(currUser);
    return this;
  }

  /**
  * Set refresh token
  * @returns {TokenStorage}
  */
  public setRefreshToken(token: string): TokenStorage {
    let currUser = this.getCurrentUser();
    currUser.refresh_token = token;
    this.setCurrentUser(currUser);
    return this;
  }

  /**
  * Remove tokens
  */
  public clear() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentPermissions');
  }

  //=== permissions ==============================================================
  /**
  * Get permissions
  */
  public getPermissions(): SysFeaturePermissionUdt[] {
    let currUser = JSON.parse(localStorage.getItem("currentPermissions"));
    return currUser;
  }

  /**
  * Set permissions
  */
  public setPermissions(permissions: SysFeaturePermissionUdt[]) {
    localStorage.setItem("currentPermissions", JSON.stringify(permissions));
    return this;
  }

  //=== voucherType ==============================================================
  /**
  * Get voucherTypes
  */
  public getVoucherTypes(): VoucherType[] {
    let currUser = JSON.parse(localStorage.getItem("currentVoucherTypes"));
    return currUser;
  }

  /**
  * Set voucherTypes
  */
  public setVoucherTypes(types: VoucherType[]) {
    localStorage.setItem("currentVoucherTypes", JSON.stringify(types));
    return this;
  }

  //=== menu toggle ==============================================================
  /**
  * Get menu toggle
  */
  public getMenuToggle(): boolean {
    let currUser = JSON.parse(localStorage.getItem("menuToggle"));
    return currUser;
  }

  /**
  * Set menu toggle
  */
  public setMenuToggle(val: boolean) {
    localStorage.setItem("menuToggle", JSON.stringify(val));
    return this;
  }
}