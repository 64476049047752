import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'stringFilter'
})
export class StringFilterPipe implements PipeTransform {

  transform(value: any[], q: string, callback: any, func?: any) {
    return callback(value, q, func);
  }
}