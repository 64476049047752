import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material';
import * as moment from 'moment';

// import { IItemLotSearchingService } from '../../../core/services/iItemLotSearching.service';
import { IWarehouseCellItemService } from '../../../core/services/iWarehouseCellItem.service';

import { Helper } from '../../../core/helpers/helper';
import { ItemLotSearching } from '../../../core/models/itemLotSearching';
import { ItemLotLogDialog } from './item-lot-log.dialog';
import { Warehouse } from '../../../core/models/warehouse';
import { WhMapCellItemDialog } from '../../warehouse/warehouse-map/wh-map-cell-item.dialog';
import { DateFormats, VoucherTypeCodes, FeatureCodes, PermissionCodes } from '../../../core/helpers/constant';
import { ReceiveInventoryRecordDetailDialog } from '../receive-inventory-record/rcv-inv-record-detail.dialog';
import { IssueInventoryRecordDetailDialog } from '../issue-inventory-record/iss-inv-record-detail.dialog';

@Component({
  templateUrl: 'item-lot-inv.dialog.html',
})
export class ItemLotInventoryDialog implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  voucherTypeCodes = VoucherTypeCodes;
  service: IWarehouseCellItemService;

  searchForm: FormGroup;
  warehouses: Warehouse[];
  itemLots: ItemLotSearching[];
  sortedData: ItemLotSearching[];
  itemLotFooter: ItemLotSearching;
  filter: string;
  selectedRow: number;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemLotInventoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public helper: Helper) { }

  ngOnInit() {
    this.itemLotFooter = new ItemLotSearching();
    this.searchForm = this.fb.group({
      warehouseId: this.data.warehouseId,
      itemCode: [this.data.itemCode, Validators.required]
    });

    if (this.data)
      this.searchForm.markAsDirty();

    this.service = this.data.service as IWarehouseCellItemService;;
    // this.search();
    this.loadWarehouse();
  }

  search() {
    this.helper.loading(true);
    const values = this.searchForm.value;
    this.service.searchItemLotInventory(values.warehouseId, values.itemCode).subscribe(resp => {
      this.itemLots = resp;
      this.sortedData = this.itemLots.slice();
      this.getItemLotTotal(this.itemLots);

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  private loadWarehouse() {
    this.helper.loading(true);
    this.service.getWarehouse().subscribe(resp => {
      this.warehouses = resp;
      this.helper.loading(false);

      this.search();
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  //WarehouseCell Item
  viewWarehouseCellItem(cellId: number) {
    if (cellId == null) return;

    this.dialog.open(WhMapCellItemDialog, {
      disableClose: true,
      data: { service: this.service, cellId: cellId }
    });
  }

  //event
  sortChange(sort: Sort) {
    if (this.itemLots == null) return;

    const itemLots = this.itemLots.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = itemLots;
      return;
    }

    this.sortedData = itemLots.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'cellCode': return this.helper.compare(a.cellCode, b.cellCode, isAsc);
        case 'warehouseCode': return this.helper.compare(a.warehouseCode, b.warehouseCode, isAsc);
        case 'maLot': return this.helper.compare(a.maLot, b.maLot, isAsc);
        // case 'netWeight': return this.helper.compare(a.netWeight, b.netWeight, isAsc);
        case 'itemCode': return this.helper.compare(a.itemCode, b.itemCode, isAsc);
        case 'itemName': return this.helper.compare(a.itemName, b.itemName, isAsc);
        case 'ngayNhapKho': return this.helper.compare(a.ngayNhapKho, b.ngayNhapKho, isAsc);
        case 'voucherCodeNhap': return this.helper.compare(a.voucherCodeNhap, b.voucherCodeNhap, isAsc);
        case 'origVoucherNhap': return this.helper.compare(a.origVoucherNhap, b.origVoucherNhap, isAsc);
        default: return 0;
      }
    });
  }

  filterCallback(value: ItemLotSearching[], q: string) {
    if (value == null || !q || q === '') {
      return value;
    }

    return value.filter(item => (item.cellCode != null && item.cellCode.toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.warehouseCode != null && item.warehouseCode.toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.maLot != null && item.maLot.toLowerCase().indexOf(q.toLowerCase()) > -1)
      // || (item.netWeight != null && item.netWeight.toString().indexOf(q.toLowerCase()) > -1)
      || (item.itemCode != null && item.itemCode.toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.itemName != null && item.itemName.toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.ngayNhapKho != null && moment(item.ngayNhapKho).format(DateFormats.ddMMyyyyHHmmss).indexOf(q) > -1)
      || (item.voucherCodeNhap != null && item.voucherCodeNhap.toLowerCase().indexOf(q.toLowerCase()) > -1)
      || (item.origVoucherNhap != null && item.origVoucherNhap.toLowerCase().indexOf(q.toLowerCase()) > -1));
  }

  getIndex(item: ItemLotSearching) {
    if (item == null || this.sortedData == null)
      return null;

    let idx = this.sortedData.findIndex(s => s.itemLotId === item.itemLotId);
    return idx + 1;
  }

  private getItemLotTotal(items: ItemLotSearching[]) {
    if (items == null || items.length === 0) {
      this.itemLotFooter.itemLotId = this.itemLotFooter.netWeight = null;
      return;
    }

    this.itemLotFooter.itemLotId = items.filter(s => s.itemLotId != null).length;
    this.itemLotFooter.netWeight = this.helper.round(items.reduce((a, b) => a + b.netWeight, 0));
  }

  rowClick(item: ItemLotSearching) {
    if (item != null)
      this.selectedRow = item.itemLotId;
  }

  //-----------------------------------------------------------------------
  // Dialog
  //-----------------------------------------------------------------------
  viewItemLotLog(item: ItemLotSearching) {
    this.dialog.open(ItemLotLogDialog, {
      disableClose: true,
      data: item.maLot
    });
  }

  viewVoucherDetail(item: ItemLotSearching, voucherType: string) {
    if (voucherType === VoucherTypeCodes.nhanNhapKho) {
      const data = { voucherId: item.voucherIdNhap, voucherCode: item.voucherCodeNhap, warehouseId: item.warehouseId };
      this.dialog.open(ReceiveInventoryRecordDetailDialog, {
        disableClose: true,
        data: data
      });
    }
    else if (voucherType === VoucherTypeCodes.nhanXuatKho) {
      const data = { voucherId: item.voucherIdXuat, voucherCode: item.voucherCodeXuat, warehouseId: item.warehouseId };
      this.dialog.open(IssueInventoryRecordDetailDialog, {
        disableClose: true,
        data: data
      });
    }
  }

  canViewVoucherDetail(voucherType: string) {
    if (voucherType === VoucherTypeCodes.nhanNhapKho)
      return this.helper.canAccess(FeatureCodes.receiveInventoryRecord, PermissionCodes.view);
    else if (voucherType === VoucherTypeCodes.nhanXuatKho)
      return this.helper.canAccess(FeatureCodes.issueInventoryRecord, PermissionCodes.view);
    return false;
  }
}