import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { VoucherBaseService } from './voucherBase.service';
import { IVoucherRecordService } from './iVoucherRecord.service';

import { environment } from '../../../environments/environment';
import { Helper } from '../helpers/helper';

import { Voucher } from '../models/voucher';

@Injectable({ providedIn: 'root' })
export class IssueInventoryRecordService extends VoucherBaseService implements IVoucherRecordService {
    // private apiUrl: string = `${environment.apiUrl}Web/NhanXuatKho/`;

    constructor(
        protected http: HttpClient,
        protected helper: Helper) {
        super(http, helper);
        this.apiUrl = `${environment.apiUrl}Web/NhanXuatKho/`;
    }

    setFinishCheck(voucherId: number, isFinish2: boolean, note: string) {
        note = this.helper.encodeURIComponent(note);

        return this.http.get(`${this.apiUrl}SetFinishCheck/${voucherId}/${isFinish2}?note=${note}`)
            .pipe(
                map((resp: any) => { return { voucher: resp.voucher as Voucher, message: resp.message as string } })
            );
    }

    //Download Voucher Report
    exportVoucherReport(fromDate: string, toDate: string, warehouseId: number, voucherCode: string, isFinish: boolean) {
        voucherCode = this.helper.encodeURIComponent(voucherCode);

        let url = `${this.apiUrl}ExportVoucherReport/${fromDate}/${toDate}/${warehouseId}/${isFinish}?voucherCode=${voucherCode}`;
        return this.http.get(url, { responseType: 'blob' as 'json', observe: 'response' })
            .pipe(
                map((res: any) => { return res })
            );
    }
}