
export class ItemLotSearching {
  itemLotId: number;
  cellId: number;
  cellCode: string;
  cellCodeFull: string;
  warehouseId: number;
  warehouseCode: string;
  maLot: string;
  rfidTag: string;
  netWeight: number;
  itemCode: string;
  itemName: string;
  tenDonVi: string;
  ngayNhapKho: Date;
  voucherIdNhap: number;
  voucherCodeNhap: string;
  origVoucherNhap: string;
  ngayXuatKho: Date;
  voucherIdXuat: number;
  voucherCodeXuat: string;
  origVoucherXuat: string;
  status: string;
  employeeName: string;

  public constructor(init?: Partial<ItemLotSearching>) {
    Object.assign(this, init);
  }
}
