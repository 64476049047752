import { Component, OnInit } from '@angular/core';

import { Helper } from './core/helpers/helper';
import { TokenStorage } from './core/helpers/token.storage';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
    private helper: Helper,
    private tokenStorage: TokenStorage,
    private commonSvc: CommonService
  ) { }

  ngOnInit() {
    //get permissions
    let currentUser = this.tokenStorage.getCurrentUser();
    if (currentUser) {
      this.helper.loading(true);
      this.commonSvc.getCommon().subscribe(resp => {
        this.tokenStorage.setPermissions(resp.userFPs);
        this.tokenStorage.setVoucherTypes(resp.voucherTypes);
        
        this.helper.loading(false);
      });
    }
  }
}
