import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material';

import { CommonService } from '../../../core/services/common.service';

import { VoucherTypes, FeatureCodes, PermissionCodes } from '../../../core/helpers/constant';
import { Helper } from '../../../core/helpers/helper';
import { ItemLotLog } from '../../../core/models/itemLotLog';
import { ReceiveInventoryRecordDetailDialog } from '../receive-inventory-record/rcv-inv-record-detail.dialog';
import { IssueInventoryRecordDetailDialog } from '../issue-inventory-record/iss-inv-record-detail.dialog';
import { TransferLocationDetailDialog } from '../transfer-location/trf-loc-detail.dialog';
import { InventoryDetailDialog } from '../inventory/inventory-detail.dialog';

@Component({
  templateUrl: 'item-lot-log.dialog.html',
})
export class ItemLotLogDialog implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  searchForm: FormGroup;
  itemLotLogs: ItemLotLog[];
  sortedData: ItemLotLog[];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ItemLotLogDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public helper: Helper,
    private commonSv: CommonService) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      keyword: [this.data, Validators.required]
    });

    if (this.data)
      this.searchForm.markAsDirty();

    this.load();
  }

  load() {
    this.helper.loading(true);
    this.commonSv.getItemLotLogFull(this.searchForm.value.keyword).subscribe(resp => {
      this.itemLotLogs = resp;
      this.sortedData = this.itemLotLogs.slice();

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  //event
  sortChange(sort: Sort) {
    if (this.itemLotLogs == null) return;

    const itemLotLogs = this.itemLotLogs.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = itemLotLogs;
      return;
    }

    this.sortedData = itemLotLogs.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'stt': return this.helper.compare(a.stt, b.stt, isAsc);
        case 'warehouseCode': return this.helper.compare(a.warehouseCode, b.warehouseCode, isAsc);
        case 'voucherCode': return this.helper.compare(a.voucherCode, b.voucherCode, isAsc);
        case 'created': return this.helper.compare(a.created, b.created, isAsc);
        case 'createdBy': return this.helper.compare(a.createdBy, b.createdBy, isAsc);
        default: return 0;
      }
    });
  }

  //-----------------------------------------------------------------------
  // Dialog
  //-----------------------------------------------------------------------
  //error: Object prototype may only be an Object or null: undefined
  viewVoucherDetail(item: ItemLotLog) {
    if (this.dialog == null || item == null || item.voucherId == null || item.voucherType == null) return;

    // const data = { voucherId: item.voucherId, voucherCode: item.voucherCode, warehouseId: item.warehouseId };
    // if (item.voucherType === VoucherTypes.nhanNhapKho || item.voucherType === VoucherTypes.nhapKho) {
    //   this.dialog.open(ReceiveInventoryRecordDetailDialog, {
    //     disableClose: true,
    //     data: data
    //   });
    // }
    // else if (item.voucherType === VoucherTypes.nhanXuatKho || item.voucherType === VoucherTypes.xuatKho) {
    //   this.dialog.open(IssueInventoryRecordDetailDialog, {
    //     disableClose: true,
    //     data: data
    //   });
    // }
    // else if (item.voucherType === VoucherTypes.chuyenViTri) {
    //   this.dialog.open(TransferLocationDetailDialog, {
    //     disableClose: true,
    //     data: data
    //   });
    // }
    // else if (item.voucherType === VoucherTypes.kiemKho) {
    //   this.dialog.open(InventoryDetailDialog, {
    //     disableClose: true,
    //     data: data
    //   });
    // }
  }

  canViewVoucherDetail(item: ItemLotLog) {
    if (item == null || item.voucherId == null || item.voucherType == null) return;

    if (item.voucherType === VoucherTypes.nhanNhapKho || item.voucherType === VoucherTypes.nhapKho)
      return this.helper.canAccess(FeatureCodes.receiveInventoryRecord, PermissionCodes.view);
    else if (item.voucherType === VoucherTypes.nhanXuatKho || item.voucherType === VoucherTypes.xuatKho)
      return this.helper.canAccess(FeatureCodes.issueInventoryRecord, PermissionCodes.view);
    else if (item.voucherType === VoucherTypes.chuyenViTri)
      return this.helper.canAccess(FeatureCodes.transferLocation, PermissionCodes.view);
    else if (item.voucherType === VoucherTypes.kiemKho)
      return this.helper.canAccess(FeatureCodes.inventory, PermissionCodes.view);
    return false;
  }
}