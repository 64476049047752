
export const DateFormats = {
    ddMMyyyy: "DD/MM/YYYY",
    yyyyMMdd: "YYYY/MM/DD",
    ddMMyyyyHHmm: "DD/MM/YYYY HH:mm",
    ddMMyyyyHHmmss: "DD/MM/YYYY HH:mm:ss",
    MMyyyy: "MM/YYYY",
    yyyyMMddJSON: "YYYY-MM-DD",
    yyyyMMddHHmmJSON: "YYYY-MM-DDTHH:mm",
    yyyyMMddHHmmssJSON: "YYYY-MM-DDTHH:mm:ss",
};

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: [DateFormats.ddMMyyyy],
    },
    display: {
        dateInput: DateFormats.ddMMyyyy,
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

export const MY_OWL_FORMATS = {
    fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false },
    datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false },
    timePickerInput: { hour: 'numeric', minute: 'numeric', hour12: false },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

export const MONTHYEAR_FORMATS = {
    parse: {
        dateInput: DateFormats.MMyyyy,
    },
    display: {
        dateInput: DateFormats.MMyyyy,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const Sensitivity = {
    base: 'base',
    accent: 'accent',
    case: 'case',
    variant: 'variant'
};

export const RoutePaths = {
    index: 'index',
    itemlot_searching: 'tim-kiem',

    login: 'dang-nhap',
    system: 'he-thong',
    system_account: 'tai-khoan',
    system_feature_permission: 'phan-quyen',
    system_role: 'vai-tro',
    system_config: 'cau-hinh',
    system_sync_log: 'nhat-ky-dong-bo',

    voucher: 'phieu',
    receive_inventory: 'nhap-kho',
    receive_inventory_record: 'nhan-nhap-kho',
    issue_inventory: 'xuat-kho',
    issue_inventory_record: 'xac-nhan-xuat-kho',
    transfer_inventory: 'chuyen-kho',
    transfer_inventory_record: 'nhan-chuyen-kho',
    transfer_location: 'chuyen-vi-tri',
    inventory: 'kiem-kho',

    warehouse: 'kho',
    warehouse_map: 'so-do-kho',
    warehouse_catalog: 'danh-muc-vi-tri',
    warehouse_category: 'danh-muc-kho',

    report: 'bao-cao',
    inventory_report: 'ton-kho-nhap-xuat',
    inventory_location_report: 'ton-kho-theo-vi-tri',

    catalog: 'danh-muc',
    catalog_itemlot: 'tai-san'
};

export const RouteLinks = {
    index: '',
    itemlot_searching: '/' + RoutePaths.index + '/' + RoutePaths.itemlot_searching,

    login: '/' + RoutePaths.login,
    system_account: '/' + RoutePaths.system + '/' + RoutePaths.system_account,
    system_feature_permission: '/' + RoutePaths.system + '/' + RoutePaths.system_feature_permission,
    system_role: '/' + RoutePaths.system + '/' + RoutePaths.system_role,
    system_config: '/' + RoutePaths.system + '/' + RoutePaths.system_config,
    system_sync_log: '/' + RoutePaths.system + '/' + RoutePaths.system_sync_log,

    receive_inventory: '/' + RoutePaths.voucher + '/' + RoutePaths.receive_inventory,
    receive_inventory_record: '/' + RoutePaths.voucher + '/' + RoutePaths.receive_inventory_record,
    issue_inventory: '/' + RoutePaths.voucher + '/' + RoutePaths.issue_inventory,
    issue_inventory_record: '/' + RoutePaths.voucher + '/' + RoutePaths.issue_inventory_record,
    transfer_inventory: '/' + RoutePaths.voucher + '/' + RoutePaths.transfer_inventory,
    transfer_inventory_record: '/' + RoutePaths.voucher + '/' + RoutePaths.transfer_inventory_record,
    transfer_location: '/' + RoutePaths.voucher + '/' + RoutePaths.transfer_location,
    inventory: '/' + RoutePaths.voucher + '/' + RoutePaths.inventory,

    warehouse_map: '/' + RoutePaths.warehouse + '/' + RoutePaths.warehouse_map,
    warehouse_catalog: '/' + RoutePaths.warehouse + '/' + RoutePaths.warehouse_catalog,
    warehouse_category: '/' + RoutePaths.warehouse + '/' + RoutePaths.warehouse_category,

    inventory_report: '/' + RoutePaths.report + '/' + RoutePaths.inventory_report,
    inventory_location_report: '/' + RoutePaths.report + '/' + RoutePaths.inventory_location_report,

    catalog_itemlot: '/' + RoutePaths.catalog + '/' + RoutePaths.catalog_itemlot,
};

export const FeatureCodes = {
    system: 'System',
    account: 'Account',
    sysPermission: 'SysPermission',
    sysRole: 'SysRole',
    config: 'Configuration',

    voucher: '_Voucher',
    receiveInventory: 'NhapKho',
    receiveInventoryRecord: 'NhanNhapKho',
    issueInventory: 'XuatKho',
    issueInventoryRecord: 'NhanXuatKho',
    transferInventory: 'ChuyenKho',
    transferInventoryRecord: 'NhanChuyenKho',
    inventory: 'KiemKho',
    transferLocation: 'ChuyenViTri',

    warehouse: '_Warehouse',
    warehouseMap: 'WarehouseMap',
    warehouseCatalog: 'WarehouseCatalog',
    warehouseCategory: 'WarehouseCategory',

    report: '_Report',
    inventoryReport: 'TonKhoNhapXuat',
    inventoryLocationReport: 'TonKhoViTri',

    itemLotSearching: 'ItemLotSearching',

    viewAllWarehouse: 'VIEWALLWAREHOUSE',
    syncLog: 'SYNCLOG',

    itemLot: 'ITEMLOT',
};

export const PermissionCodes = {
    view: 'VIEW',
    add: 'ADD',
    edit: 'EDIT',
    delete: 'DELETE',
    unfinish: 'UNFINISH'
};

export const ItemsPerPage = 10;
export const GuidEmpty = "00000000-0000-0000-0000-000000000000";
export const EmailRegex = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$";
export const JoinSeparator = ",";
export const ReadMoreMaxLength = 40;
export const VoucherNoteMaxLength = 512;

export const Messages = {
    notAllowed: "Bạn không có quyền truy cập trang này. Vui lòng liên hệ quản trị viên.",
    notAllowedToast: "Bạn không có quyền thực hiện.",
    saveSuccessfully: "Lưu dữ liệu thành công.",
    deleteSuccessfully: "Xóa dữ liệu thành công.",
    changePasswordSuccessfully: "Đổi mật khẩu thành công.",
    confirmDelete: "Bạn có chắc muốn xóa không?",
    confirmContinue: "Bạn có muốn tiếp tục không?",
    mandatory: "Bạn cần điền đẩy đủ thông tin.",
    notFound: "Không tìm thấy dữ liệu. Bạn không có quyền hoặc dữ liệu đã bị xóa.",
    exists: "{0} đã tồn tại. Bạn vui lòng nhập lại.",
    inUse: "{0} đã được sử dụng trong hệ thống. Bạn không thể xóa.",
    error500: "Có lỗi xảy ra trong quá trình xử lý. Bạn vui lòng thử lại.",
    errorQuota: "Thông tin định mức: có lỗi xảy ra trong quá trình xử lý.",
    quotaIsNull: "Chưa có thông tin định mức."
};

export const Texts = {
    companyName: "Asset Management",
    accountInactive: "Tài khoản đã bị khóa.",
    loginFail: "Tên đăng nhập hoặc mật khẩu không đúng.",
    noData: "Không có dữ liệu.",
    filter: "Nhập từ khóa...",
    enterKeyword: "Nhập từ khóa...",
    notFound: "Không tìm thấy",
    previousLabel: "Trước",
    nextLabel: "Sau",

    loadBtn: "Lấy dữ liệu",

    addLink: "Tạo mới",
    editLink: "Sửa",
    deleteLink: "Xóa",
    viewDetailLink: "Xem chi tiết",
    printLink: "In nhãn",

    accountAdd: "Tạo tài khoản",
    accountResetPwd: "Đặt lại mật khẩu",
    accountViewWarehouse: "Xem danh sách phòng ban",

    tabLabelItem: "Mã kế toán",
    tabLabelDetail: "Chi tiết",
    placeholderMaLot: "Tài sản",

    itemCodeShort: "Mã kế toán",
    itemCode: "Mã kế toán",
    itemName: "Tên loại tài sản",
    cellCode: "Vị trí",
    cellCodeFrom: "Từ vị trí",
    cellCodeTo: "Tới vị trí",
    warehouse: "Phòng ban",
    warehouseCode: "Mã phòng ban",
    warehouseName: "Tên phòng ban",
    warehouseCodeFrom: "Từ phòng ban",
    warehouseCodeTo: "Tới phòng ban",
    warehouseParent: "Thuộc phòng ban",
    voucherAdd: "Tạo phiếu",
    voucherCode: "Số phiếu",
    voucherCodeFilter: "Số phiếu",
    voucherMaLot: "Tài sản",
    voucherMaLotIss: "Tài sản xác nhận",
    voucherMaLotRcv: "Tài sản nhận",
    voucherNetWeight: "Khối lượng",
    voucherNetWeightRequest: "Khối lượng cần",
    voucherNetWeightIss: "Khối lượng xác nhận",
    voucherNetWeightIssShort: "KL xác nhận",
    voucherNetWeightRcv: "Khối lượng nhận",
    voucherNetWeightRcvShort: "KL nhận",
    voucherCreated: "Ngày tạo",
    voucherCreatedBy: "Người tạo",
    voucherStart: "Start",

    warehouseCatalogMaxSKU: "Số mã hàng tối đa",
    warehouseCatalogMaxCount: "Số lượng tối đa",
    warehouseCatalogMaxKG: "Trọng lượng tối đa",
    warehouseCatalogMoveUp: "Di chuyển lên",
    warehouseCatalogMoveDown: "Di chuyển xuống",

    warehouseAdd: "Tạo phòng ban",

    reportInv_OpeningQty: "Số lượng đầu kỳ",
    reportInv_ClosingQty: "Số lượng cuối kỳ",
    reportInv_InputQty: "Số lượng nhập",
    reportInv_OutputQty: "Số lượng xuất",
};

export const MenuTexts = {
    system: "Hệ thống",
    account: "Người dùng",
    sysPermission: "Phân quyền",
    sysRole: "Vai trò người dùng",
    config: "Cấu hình",
    syncLog: "Nhật ký đồng bộ",

    voucher: "Phiếu",
    receiveInventory: "Nhập tài sản",
    receiveInventoryEdit: "Phiếu nhập tài sản",
    receiveInventoryRecord: "Nhận nhập tài sản",
    issueInventory: "Thu hồi tài sản",
    issueInventoryEdit: "Phiếu thu hồi tài sản",
    issueInventoryRecord: "Xác nhận xuất tài sản",
    transferInventory: "Chuyển tài sản",
    transferInventoryEdit: "Phiếu chuyển tài sản",
    transferInventoryRecord: "Nhận chuyển tài sản",
    transferLocation: "Chuyển vị trí tài sản",
    transferLocationEdit: "Phiếu chuyển vị trí tài sản",
    inventory: "Kiểm kê tài sản",
    inventoryEdit: "Phiếu kiểm kê tài sản",

    warehouse: "Phòng ban",
    warehouseMap: "Sơ đồ phòng ban",
    warehouseCatalog: "Danh mục vị trí",
    warehouseCategory: "Danh mục phòng ban",

    report: "Báo cáo",
    inventoryReport: "Tồn kho nhập xuất",
    inventoryLocationReport: "Tồn kho theo vị trí",

    itemLotSearching: "Tìm kiếm",

    catalog: "Danh mục",
    catalogItemLot: "Thông tin tài sản",
};

export const ContextMenuTexts = {
    start: "Start",
    stop: "Stop",
    finish: "Hoàn tất",
    removeFinish: "Gỡ hoàn tất",
    finish2: "Hoàn tất nhận",
    removeFinish2: "Gỡ hoàn tất nhận",
    finish3: "Hoàn tất xác nhận",
    removeFinish3: "Gỡ hoàn tất xác nhận",
    exportReceiveInventory: "Xuất phiếu",
    exportIssueInventory: "Xuất phiếu",
    exportTransferInventory: "Xuất phiếu",
    exportTransferLocation: "Xuất phiếu",
    exportInventory: "Xuất phiếu",
    viewItemLotLog: "Xem nhật ký",
    exportVoucherInventoryReport: "Xuất báo cáo kiểm kê tài sản",
    itemLotInventory: "Tồn tài sản ",
    updateOperator: "Gán người quét",
};

export const WarehouseMapProp = {
    shelf: "shelf",
    name: "Name",
    color: "Color",
    isLocked: "IsLocked"
};

export const VoucherTypeCodes = {
    nhapKho: "NHAPKHO",
    nhanNhapKho: "NHANNHAPKHO",
    xuatKho: "XUATKHO",
    nhanXuatKho: "NHANXUATKHO",
    chuyenKho: "CHUYENKHO",
    nhanChuyenKho: "NHANCHUYENKHO",
    kiemKho: "KIEMKHO",
    chuyenViTri: "CHUYENVITRI",
};

export const VoucherTypes = {
    nhapKho: 10,
    nhanNhapKho: 11,
    xuatKho: 20,
    nhanXuatKho: 21,
    chuyenKho: 30,
    nhanChuyenKho: 31,
    kiemKho: 40,
    chuyenViTri: 50,
};