import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AccountService } from '../../../core/services/account.service';

import { Helper } from '../../../core/helpers/helper';
import { Messages } from "../../../core/helpers/constant";

@Component({
  selector: 'account-change-password-dialog',
  templateUrl: 'account-change-password.dialog.html',
})
export class AccountChangePasswordDialog implements OnInit {
  editForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AccountChangePasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private helper: Helper,
    private accountSvc: AccountService) { }

  ngOnInit() {
    this.editForm = this.fb.group({
      oldPassword: [null, [Validators.required, Validators.minLength(6)]],
      newPassword: [null, [Validators.required, Validators.minLength(6)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6)]],
    }, { validator: this.matchPassword });
  }

  private matchPassword(c: FormGroup): { mismatch: boolean } {
    if (c.controls.newPassword.value && c.controls.confirmPassword.value) {
      if (c.controls.newPassword.value !== c.controls.confirmPassword.value) {
        c.controls.confirmPassword.setErrors({ 'incorrect': true });
        return { mismatch: true };
      }
      else
        c.controls.confirmPassword.setErrors(null);
    }
  }

  save() {
    if (this.editForm.invalid) return;

    this.helper.loading(true);
    this.accountSvc.changePassword(this.editForm.getRawValue()).subscribe(resp => {
      this.dialogRef.close(true);
      this.helper.loading(false);
      this.helper.showSuccess(Messages.changePasswordSuccessfully);
    },
      (ex) => {
        this.helper.loading(false);
        this.helper.showError(ex);
      });
  }
}