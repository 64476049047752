import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ReceiveInventoryService } from '../../../core/services/receiveInventory.service';

import { Helper } from '../../../core/helpers/helper';
import { Item } from '../../../core/models/Item';

@Component({
  templateUrl: 'rcv-inv-item-edit.dialog.html',
})
export class ReceiveInventoryItemEditDialog implements OnInit {
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  filteredItemCtrl = new FormControl();
  isLoading = false;
  public filteredItem: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);
  protected _onDestroy = new Subject<void>();

  editForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReceiveInventoryItemEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public helper: Helper,
    private rcvInvSv: ReceiveInventoryService) { }

  ngOnInit() {
    this.filteredItemCtrl
      .valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.isLoading = true),
        takeUntil(this._onDestroy),
        debounceTime(500),
        switchMap(value => this.rcvInvSv.searchItem(value, null)),
        takeUntil(this._onDestroy)
      )
      .subscribe(resp => {
        this.isLoading = false;
        this.filteredItem.next(resp);
      },
        ex => {
          this.isLoading = false;
        });

    this.editForm = this.fb.group({
      voucherItemId: [0, Validators.required],
      voucherId: [0, Validators.required],
      item: [{ value: null, disabled: this.data != null && this.data.voucherItemId > 0 }, Validators.required],
      itemCode: null,
      requestQty: null,
      requestWeight: null,
      note: null
    });

    if (this.data != null) {
      let item: Item = null;
      if (this.data.voucherItemId > 0) {
        item = new Item({
          itemCode: this.data.itemCode,
          itemName: this.data.itemName,
        });
        this.filteredItem.next([item]);
      }

      this.editForm.patchValue({
        voucherItemId: this.data.voucherItemId,
        voucherId: this.data.voucherId,
        item: item,
        itemCode: this.data.itemCode,
        requestQty: this.data.requestQty,
        requestWeight: this.data.requestWeight,
        note: this.data.note
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  save() {
    if (this.editForm.invalid) return;

    this.helper.loading(true);
    let req = this.editForm.getRawValue();
    req.itemCode = req.item.itemCode;

    this.rcvInvSv.saveItem(req).subscribe(resp => {
      this.dialogRef.close(resp);

      this.helper.loading(false);
    }, ex => {
      this.helper.loading(false);
      this.helper.showError(ex);
    });
  }

  clearItem(e: any) {
    this.editForm.patchValue({ item: null });
    e.stopPropagation();
  }
}