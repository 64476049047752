import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of, } from 'rxjs';
import { Router } from '@angular/router';

import {TokenStorage} from '../helpers/token.storage';
import { RouteLinks } from '../helpers/constant';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	//https://angular-academy.com/angular-jwt/
    constructor(
        private router: Router,
        private tokenStorage: TokenStorage,
        private authSvc: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        // add authorization header with jwt token if available
        let currentUser = this.tokenStorage.getCurrentUser();
        //if (currentUser && currentUser.access_token) {
		if (currentUser && currentUser.access_token && request.url.search(/\/token/gi) == -1){
            request = this.addToken(request, currentUser.access_token);
        }
		
		return next.handle(request).pipe(catchError(error => {
			if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
			} else {
				return throwError(error);
			}
		}));
    }

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				//'Access-Control-Allow-Origin':'*',
				'Authorization': `Bearer ${token}`
			}
		});
	}

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true; 
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null); 
			
            return this.authSvc.refreshToken()
                .pipe(
                    switchMap((newToken: any) => {//debugger;
                        if (newToken && newToken.access_token) {
                            //this.isRefreshingToken = false; 
							this.tokenSubject.next(newToken.access_token);
                            return next.handle(this.addToken(request, newToken.access_token));
                        } else {
                            // If we don't get a new token, we are in trouble so logout.
                            return this.logoutUser();
                    }}),
                    // //DO NOT THIS, so the next request that gets the error => logout our app.
                    // catchError(error => {
                    //     // If there is an exception calling 'refreshToken', bad news so logout.
                    //     return this.logoutUser();
                    // }),
                )
                .pipe (
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
			return this.tokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(jwt => {
					return next.handle(this.addToken(request, jwt));
			}));
        }
    }

    private logoutUser() {
        // Route to the login page (implementation up to you)
        this.tokenStorage.clear();
        this.router.navigate([RouteLinks.login], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
        //return Observable.throw("");
        return of(false);
    }
}