import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IsLoadingService } from '@service-work/is-loading';

import { Helper } from '../../../core/helpers/helper';
import { TokenStorage } from '../../../core/helpers/token.storage';
import { RouteLinks } from '../../../core/helpers/constant';
import { AccountChangePasswordDialog } from '../../system/account/account-change-password.dialog';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly routeLinks = RouteLinks;
  fullName: string;
  isLoading: Observable<boolean>;
  isMenuToggle: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    public helper: Helper,
    private tokenStorage: TokenStorage,
    private isLoadingService: IsLoadingService
  ) { }

  ngOnInit() {
    this.isLoading = this.isLoadingService.isLoading$();

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });

    let user = this.tokenStorage.getCurrentUser();
    if (user != null)
      this.fullName = user.fullName;

    if (this.tokenStorage.getMenuToggle())
      this.helper.toggleMenu();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewChecked() {
    // $("#spinner").hide();
  }

  logOut() {
    this.tokenStorage.clear();
    this.router.navigate([RouteLinks.login]);
  }

  isLogged() {
    let user = this.tokenStorage.getCurrentUser();
    return user != null;
  }
  
  changePassword() {
    const dialogRef = this.dialog.open(AccountChangePasswordDialog, {
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(resp => {
      
    });
  }

  sidebarToggle(e: any) {
    let toggle = this.tokenStorage.getMenuToggle();
    this.tokenStorage.setMenuToggle(!toggle);

    e.preventDefault();
    this.helper.toggleMenu();
  }
}
