import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'read-more',
  template: `
    <span class="nl2br">{{currentText}}</span>
    <a href="javascript:;" (click)="toggleView()">
        <span *ngIf="less" matTooltip="Thu nhỏ" style="padding-left: 7px"><i class="fa fa-angle-up" aria-hidden="true"></i></span>
        <span *ngIf="more" matTooltip="Xem thêm">... <i class="fa fa-angle-right" aria-hidden="true"></i></span>
    </a>
  `,
})
export class ReadMoreComponent implements AfterViewInit {
  private _text: string;
  @Input() set text(value: string) {
    this._text = value;
    this.init();
  }
  get text(): string {
    return this._text;
  }

  @Input() maxLength: number;
  @Input() maxRow: number = 2;

  currentText: string;
  more: boolean = false;
  less: boolean = false;
  private isCollapsed: boolean = true;
  private rowsInText: number = 0;
  private firstLoad = true;

  constructor(private cdr: ChangeDetectorRef) { }

  public ngAfterViewInit() {
    this.textChange();
    this.firstLoad = false;

    this.cdr.detectChanges();
  }

  private textChange() {
    if (this.text == null) this.text = "";
    this.rowsInText = this.text.trim().split(/\r\n|\r|\n/).length;

    this.toggleView();
  }

  private init() {
    if (this.firstLoad) return;

    this.textChange();
  }

  /**
   * Toogle view - full text or not
   */
  toggleView(): void {
    this.determineView();
    this.isCollapsed = !this.isCollapsed;

    if (this.text.length <= this.maxLength && (this.maxRow == null || this.maxRow <= 0 || this.rowsInText <= this.maxRow)) {
      this.more = this.less = false;
    } else {
      if (this.isCollapsed) {
        this.more = false;
        this.less = true;
      } else {
        this.more = true;
        this.less = false;
      }
    }
  }

  /**
   * Determine view
   */
  private determineView(): void {
    if (this.text.length <= this.maxLength && (this.maxRow == null || this.maxRow <= 0 || this.rowsInText <= this.maxRow)) {
      this.currentText = this.determineRowView(this.text);
      this.isCollapsed = false;
      return;
    }

    if (this.isCollapsed === true) {
      let str = this.determineRowView(this.text);
      if (str.length > this.maxLength) {
        str = str.substring(0, this.maxLength);
        if (this.text.charAt(this.maxLength + 1) !== ' ') {
          let i = str.length;
          for (; i >= 0; i--)
            if (str[i] === ' ') break;
          str = str.substring(0, i);
        }
        // str += '...';
      }
      else {
        // if (this.text.length !== str.length)
        //   str += '...';
      }

      this.currentText = str;
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
    }
  }

  private determineRowView(str: string): string {
    if (this.maxRow == null || this.maxRow <= 0 || this.rowsInText <= this.maxRow) return str;

    let lines = str.trim().split('\n');
    for (let i = lines.length; i >= this.maxRow; i--)
      lines.splice(i, 1);
    return lines.join('\n');
  }
}