import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { SysFeaturePermissionUdt } from '../models/sysFeaturePermissionUdt';
import { ItemLotLog } from '../models/itemLotLog';

@Injectable({ providedIn: 'root' })
export class CommonService {
    private apiUrl: string = `${environment.apiUrl}Common/`;

    constructor(private http: HttpClient) { }

    //Permission
    getPermissions() {
        return this.http.get(`${this.apiUrl}GetPermissions`)
            .pipe(
                map((res: SysFeaturePermissionUdt[]) => { return res })
            );
    }

    //Common: permission, voucherType
    getCommon() {
        return this.http.get(`${this.apiUrl}GetCommon`)
            .pipe(
                map((res: any) => { return { userFPs: res.userFPs, voucherTypes: res.voucherTypes } })
            );
    }

    //ItemLotLogFull
    getItemLotLogFull(qrCode: string) {
        if (qrCode == null) qrCode = '';

        return this.http.get(`${this.apiUrl}GetQRCodeLogFull?qrcode=${qrCode}`)
            .pipe(
                map((res: ItemLotLog[]) => { return res })
            );
    }
}