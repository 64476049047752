import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material";

import { TokenStorage } from '../../../core/helpers/token.storage';
import { Helper } from '../../../core/helpers/helper';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { CommonService } from "../../../core/services/common.service";

@Component({
  selector: "system-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('userName') userName: ElementRef;
  loginForm: FormGroup;
  private returnUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public helper: Helper,
    private tokenStorage: TokenStorage,
    private authSvc: AuthenticationService,
    private commonSvc: CommonService
  ) { }

  ngOnInit() {
    this.dialog.closeAll();

    this.tokenStorage.clear();
    let currentUser = this.tokenStorage.getCurrentUser();
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    if (currentUser != null) {
      this.router.navigate(['/']);
    }
    else {
      this.loginForm = this.fb.group({
        userName: [null, Validators.required],
        password: [null, Validators.required]
      });
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.userName.nativeElement.focus());
  }

  login() {
    this.helper.loading(true);
    this.authSvc.login(this.loginForm.controls.userName.value, this.loginForm.controls.password.value).subscribe(data => {
      //get common
      this.commonSvc.getCommon().subscribe(resp => {
        this.tokenStorage.setPermissions(resp.userFPs);
        this.tokenStorage.setVoucherTypes(resp.voucherTypes);

        this.helper.loading(false);
        this.router.navigate([this.returnUrl]);
      });
    }, ex => {
      this.helper.loading(false);
      if (ex != null && ex.error != null && this.helper.equals(ex.error.error, "UserInactive"))
        this.helper.showErrorStr(this.helper.texts.accountInactive);
      else
        this.helper.showWarning(this.helper.texts.loginFail);
    });
  }
}
